<template>
  <div class="home-box">
    <div class="m-title4">
      <!-- 使用您的PSC -->
      {{ $t("use.pscBox2T") }}
    </div>
    <div class="box">
      <div class="p">
        <!-- 现在，您已经持有了一些PSC。您可以查看一些PSC网络应用（去中心化应用）。有很多类型的去中心化应用，包括金融、社交、媒体、游戏，还有其他各种领域。 -->
        {{ $t("use.pscBox2P") }}
      </div>
      <div class="m-but2" @click="uti.goPath(store.link.use.box2But)">
        <!-- 查看去中心化应用 -->
        {{ $t("use.pscBox2But") }}
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.home-box {
  // height: 1213.1px;
  // background: #f4faff, url("../../assets/images/v4/home/box2Box.png");
  background-color: #f4faff;
  padding-top: (68 / 3.75vw);
  box-sizing: border-box;
  background-repeat: no-repeat;
  text-align: center;

  .box {
    height: (244 / 3.75vw);
    background-image: url("../../assets/images/v4/use/m/getBox2.png");
    background-size: 100% 100%;
    position: relative;
    margin-top: (36 / 3.75vw);
    box-sizing: border-box;
    padding: (35 / 3.75vw) (14 / 3.75vw);
    .p {
      font-size: (12 / 3.75vw);
      line-height: (25 / 3.75vw);
      font-weight: 500;
      color: #999999;
    }
    .m-but2 {
      text-align: center;
      position: absolute;
      display: inline-block;
      white-space: nowrap;
      left: 50%;
      bottom: (25 / 3.75vw);
      transform: translateX(-50%);
    }
  }
}
</style>
