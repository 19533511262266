<template>
  <div class="dev-item">
    <div class="m-title">
      <!-- 在哪里购买PSC -->
      {{ $t("use.pscBox1T") }}
    </div>
    <div class="m-p">
      <!-- 您可以通过交易所或者钱包应用直接购买PSC。搜索您的所在地区有哪些可用服务。 -->
      {{ $t("use.pscBox1P1") }}
      <a href="javascript:;" @click="uti.goPath(store.link.use.swap)">
        <span class="lan">
          {{ $t("use.pscBox1P2") }}
        </span>
      </a>
      {{ $t("use.pscBox1P3") }}
    </div>
    <div class="box">
      <div class="top">
        <!-- 当前PSC价格 (美元) -->
        {{ $t("use.pscBox1BoxT") }}
      </div>
      <div class="bigNum">${{ store.homePrice }}</div>
      <div class="bot" v-if="false">
        <div class="span">
          4.65%<svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-youshang01"></use>
          </svg>
        </div>
        <div class="mini">
          (
          <!-- 最近24小时 -->
          {{ $t("use.pscBox1BoxB") }}
          )
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Store from "../../store";
export default {
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
};
</script>

<style lang="less" scoped>
.dev-item {
  height: (357.33 / 3.75vw);
  background: url("../../assets/images/v4/use/getBox1BG.png"),
    linear-gradient(0deg, #dae4f0, #eef5fd);
  background-size: (264 / 3.75vw) (244 / 3.75vw), 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: (115 / 3.75vw);
  box-sizing: border-box;
  position: relative;
  text-align: center;
  .m-p {
    padding: (22 / 3.75vw) (14 / 3.75vw) 0;
  }
  .box {
    left: 50%;
    bottom: -(31.67 / 3.75vw);
    position: absolute;
    transform: translateX(-50%);
    margin: 0 auto;
    width: (246.67 / 3.75vw);
    height: (123 / 3.75vw);
    margin-top: (51 / 3.75vw);
    background: linear-gradient(180deg, #2164fc, #40c3f9);
    border-radius: (6.67 / 3.75vw);
    padding-top: (13 / 3.75vw);
    box-sizing: border-box;
    .top {
      font-size: (12 / 3.75vw);
      color: #ffffff;
    }
    .bigNum {
      font-size: (26.67 / 3.75vw);
      font-weight: bold;
      color: #ffffff;
      margin-top: (23.67 / 3.75vw);
    }
    .bot {
      width: 100%;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      margin-top: (15 / 3.75vw);
      justify-content: space-around;
      // justify-content: center;
      font-size: (15 / 3.75vw);
      .mini {
        display: inline-block;
        font-size: (12 / 3.75vw);
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
